<template>
  <div class="content-unavailable-mask flex">
    <div class="content-unavailable-mask-backdrop"></div>

    <div class="content-unavailable-mask-isotype text-center">
      <object
        v-if="$store.state.Global.online && !$props.appWithNoContent"
        width="100%"
        height="100%"
        :data="isotype"
        ref="Isotype"
        type="image/svg+xml"
      ></object>
      <img
        v-else
        ref="IsotypePng"
        src="/img/logotype/onca-vega-isotipo-240.png"
        alt="Onca Vega Logo"
        width="100%"
        height="100%"
      />
    </div>
    <div ref="OfflineText" class="content-unavailable-mask-container">
      <hr class="secondary" />
      <div class="flex-sm">
        <h3 class="content-unavailable-mask-container-element text-justify text-sm-right">
          Esta página no está disponible porque no tienes conexión a internet. Conéctate de nuevo
          para poder ver este contenido.
        </h3>
        <hr class="primary" />
        <h3 class="content-unavailable-mask-container-element text-justify text-sm-left">
          This page is not available because there is no internet connection. To see this content
          you need to connect again.
        </h3>
      </div>
      <hr class="secondary" />
    </div>
  </div>
</template>

<script>
import isotype from "@/assets/images/isotype/onca-vega-isotipo-240.svg";

export default {
  name: "ContentUnavailableMask",
  props: {
    appWithNoContent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isotype,
      isotypeLoaded: false,
      loading: true
    };
  },
  methods: {
    /*
     * @function - animateOfflineStuff
     * Calls isotype png animation and no content text
     * @param - none
     */
    animateOfflineStuff() {
      const { OfflineText, IsotypePng } = this.$refs;
      this.m_animateFade(OfflineText, 1600, "in");
      this.m_animateFade(IsotypePng, 800, "in")
        .then(() => this.m_animateFade(this.$refs.IsotypePng, 800))
        .then(() => this.m_animateFade(this.$refs.IsotypePng, 800, "in"))
        .then(() => this.m_animateFade(this.$refs.IsotypePng, 800))
        .then(() => this.m_animateFade(this.$refs.IsotypePng, 800, "in"));
    },
    /*
     * @function - isotypeListener
     * Adds or removes event listener for isotype svg image animation
     * @param - state (String): if true, listener will be added, otherwise will be removed
     */
    isotypeListener(state = true) {
      const { Isotype } = this.$refs;

      if (Isotype) {
        if (!state) {
          Isotype.removeEventListener("load", this.animateIsotype);
        } else if (!this.isotypeLoaded) {
          this.isotypeLoaded = true;
          Isotype.addEventListener("load", this.animateIsotype);
        } else {
          this.animateIsotype();
        }
      }
    },
    /*
     * @function - animateIsotype
     * Calls isotype animation
     * @param - none
     */
    animateIsotype() {
      this.m_animateIsotype(this.$refs.Isotype.contentDocument, 1500, false);
    },
    /*
     * @function - clearLoadingMask
     * Clears loading mask after everything is loaded
     * @param - emit (Boolean): If true, emits loaded to parent, otherwise avoid doing it
     */
    clearLoadingMask(emit = true) {
      if (emit) {
        this.$emit("loaded");
      }
      this.isotypeListener(false);
      this.m_removeImagotypeAnimation();
      this.loading = false;
    }
  },
  mounted() {
    if (this.loading) {
      this.$store.state.Global.online ? this.isotypeListener() : this.animateOfflineStuff();
    }
  },
  beforeDestroy() {
    this.clearLoadingMask(false);
  }
};
</script>

<style lang="scss" scoped>
.content-unavailable-mask {
  bottom: 0;
  display: grid;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;

  &-backdrop {
    background-color: $gray-blue;
    height: 100%;
    opacity: 1;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  &-isotype {
    margin: auto;
  }

  &-container {
    opacity: 0;
    padding: 1.5rem 0.75rem;

    @include breakpoint($small) {
      padding: 2rem 1.25rem;
    }

    &-element {
      display: block;
      margin: 1rem 0.5rem;

      @include breakpoint($xsmall) {
        margin: 1rem 2rem;
      }

      @include breakpoint($small) {
        flex: 1 0;
      }

      @include breakpoint($medium) {
        margin: 1rem 4rem;
      }

      @include breakpoint($large) {
        margin: 1rem 5rem;
      }
    }
  }
}
</style>
